import type { GetStaticPropsContext, InferGetStaticPropsType } from "next";

import { NextSeo } from "next-seo";
import Layout from "../components/layout/LayoutWrapper";
import HomeShow from "../components/home/HomeShow";
import { formatSanitySeo } from "../utils/seo";
import { SHOPIFY_DEFAULT_LOCATION } from "library/utils/shopify";
import { isProduction } from "../hooks/useFlags";
import { staticPropsError } from "../utils/error";
import { calculateObjectSize } from "library/utils/stats";
import PageLoading from "../components/page/PageLoading";
import { createHashKey } from "library/utils";
import { fetchHomePage, routeFetcher } from "library/services/fetchers";

export async function getStaticProps({
  locale = SHOPIFY_DEFAULT_LOCATION,
  preview = false,
}: GetStaticPropsContext) {
  try {
    const key = createHashKey("homePage", { locale, preview });
    const data = await routeFetcher({
      key,
      fetcher: () => fetchHomePage({ locale, preview }),
      preview,
    });

    const { kiloBytes } = calculateObjectSize(data);
    console.log(`Home size: ${kiloBytes}kb`);

    return {
      revalidate: isProduction ? undefined : 1,
      props: { data },
    };
  } catch (e: any) {
    console.error(e);
    return staticPropsError(e);
  }
}

export default function Home({
  data,
}: InferGetStaticPropsType<typeof getStaticProps>) {
  if (data?.error) return <PageLoading />;
  const { segment, seo } = data;

  return (
    <>
      <NextSeo {...formatSanitySeo("/", { seo })} />
      <HomeShow segment={segment} />
    </>
  );
}

Home.Layout = Layout;
